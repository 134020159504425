<div class="card">
    <div class="card-header">
        <h1 class="h3 mb-2 text-gray-800">User</h1>
    </div>
    <div class="card-body">
        <form (ngSubmit)="SaveUser()" [formGroup]="userForm" >
            <div class="form-row">
                <div class="col">
                    <label>User Name</label>
                    <input required type="text" formControlName="userName" class="form-control" placeholder="User Name" autocomplete="off">
                    <div *ngIf="userName.invalid && (userName.dirty || userName.touched)">
                        <div *ngIf="userName.errors?.required">User Name is required.</div>
                    </div>
                </div>
                <div class="col">
                    <label>Email</label>
                    <input required type="email" formControlName="email" class="form-control" placeholder="Email" autocomplete="off">
                    <div *ngIf="email.invalid && (email.dirty || email.touched)">
                        <div *ngIf="email.errors?.required">Email is required.</div>
                    </div>
                </div>
            </div>
            <br/>
            <div class="form-row">
                <div class="col">
                    <label>Password</label>
                    <input required type="password" formControlName="password"class="form-control" placeholder="Password" autocomplete="off">
                    <div *ngIf="password.invalid && (password.dirty || password.touched)">
                        <div *ngIf="password.errors?.required">Password is required.</div>
                    </div>
                </div>
                <div class="col">
                    <label>Role</label>
                    <select required class="form-control" formControlName="userRole">
                        <option *ngFor="let item of roles" [value]="item.id">{{item.name}}</option>
                    </select>
                    <div *ngIf="userRole.invalid && (userRole.dirty || userRole.touched)">
                        <div *ngIf="userRole.errors?.required">User Role is required.</div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                
                <div class="col">
                    <label>Expiry Date</label>
                    <input required type="date" formControlName="expiryDate"class="form-control" autocomplete="off">

                    <div *ngIf="expiryDate.invalid && (expiryDate.dirty || expiryDate.touched)">
                        <div *ngIf="expiryDate.errors?.required">Expiry Date is required.</div>
                    </div>
                </div>
                <div class="col">
                    <label>Is IP Verification Required</label><br/>
                    <input type="checkbox" formControlName="isVerificationRequired"  autocomplete="off">
                   
                </div>
            </div>
            <div class="form-row">
                <div class="col">
                    <label>Is Active </label><br/>
                    <input type="checkbox" formControlName="isActive" autocomplete="off">
                   
                </div>
            </div>
            <div class="mt-1">
                <button type="submit" [disabled]="userForm.invalid" class="btn btn-primary btn-user btn-block m-1 col-2 ml-auto">
                    {{isEdit?'Update':'Submit'}}
                </button>
            </div>
        </form>
        <br/>
              <ag-grid-angular 
        [rowData]="rowData" [columnDefs]="colDefs" class="ag-theme-quartz" style="height: 500px;" />
    </div>
</div>
<notifier-container></notifier-container>