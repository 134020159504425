import { Component } from '@angular/core';
import { AuthenticationService } from '../service/authentication.service';
import { CommonModule, formatDate } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { error } from 'console';
import { NotifierModule, NotifierService } from 'angular-notifier';
import { AgGridAngular } from 'ag-grid-angular';
import { ActionButtonRendererComponentComponent } from '../ag-grid/action-button-renderer-component/action-button-renderer-component.component';
import moment from 'moment';

@Component({
  selector: 'app-user',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NotifierModule, AgGridAngular],
  templateUrl: './user.component.html',
  styleUrl: './user.component.css'
})
export class UserComponent {

  roles: any[] = [];
  userForm: FormGroup;

  colDefs: any[] =
    [
      { field: "userName", headerName: "User Name" },
      { field: "email", headerName: "Email" },
      { field: "role", headerName: "Role", cellRenderer: (params: any) => params?.data?.userRole?.role?.name },
      { field: "expiryDate", headerName: "Expiry Date" , valueFormatter: this.dateFormatter},
      { field: "isVerificationRequired", headerName: "Is Verification Required" },
      { field: "isActive", headerName: "Is Active" },
      {
        headerName: 'Actions',
        cellRenderer: ActionButtonRendererComponentComponent,
        width: 150,
        cellRendererParams: {
          onDelete: this.onDelete.bind(this),
          onEdit: this.onEdit.bind(this),
        }
      },
    ]
  rowData: any[] = [];
  ngOnInit() {
    this.userForm = this.fb.group({
      id: [0, []],
      isActive: [true, []],
      userName: ['', [Validators.required]],
      password: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      userRole: ['', [Validators.required]],
      isVerificationRequired: [true, [Validators.required]],
      expiryDate: ['', [Validators.required]]
    });
  }

  dateFormatter(params: any) {
    return moment(params.value).format('DD-MM-YYYY');
  }

  onDelete(obj: any) {

    this.authService.getUserProfile().subscribe((profile) => {
      if (profile.userName == obj.userName) {
        this.notifier.notify('warning', 'Not allowed to delete the User!');
        return;
      } else {
        this.delete(obj.id);
      }
    })
  }
  isEdit: boolean = false;
  onEdit(obj: any) {
    var requestBody = JSON.parse(JSON.stringify(obj));
    requestBody.userRole = obj.userRole.role.id;
    this.isEdit = true;
    requestBody.expiryDate = formatDate(obj.expiryDate,'yyyy-MM-dd','en')
    this.userForm.setValue(requestBody);
  }

  delete(id: number) {
    this.authService.deleteUser(id).subscribe((resp) => {
      this.getUser();
      this.notifier.notify('success', 'User deleted Successfully!');
    })
  }

  get userName() {
    return this.userForm.get('userName');
  }

  get password() {
    return this.userForm.get('password');
  }
  get email() {
    return this.userForm.get('email');
  }
  get userRole() {
    return this.userForm.get('userRole');
  }
  get isActive() {
    return this.userForm.get('isActive');
  }
  get isVerificationRequired() {
    return this.userForm.get('isVerificationRequired');
  }
  get expiryDate() {
    return this.userForm.get('expiryDate');
  }
  constructor(private authService: AuthenticationService, private fb: FormBuilder, private notifier: NotifierService) {
    this.getRole();
    this.getUser();
  }
  SaveUser() {
    if (this.userForm.valid) {

      var body = JSON.parse(JSON.stringify(this.userForm.value));
      body.userRole = {
        roleId: this.userForm.value.userRole
      }
      if (this.isEdit) {
       

        this.authService.updateUser(body).subscribe((resp: any) => {
          this.notifier.notify('success', 'User updated successfully!');
          this.userForm.reset();
          this.getUser();
          this.isEdit = false;
        }, (error) => {
          this.notifier.notify('error', 'User update failed!');
        })
      } else {
        this.authService.saveUser(body).subscribe((resp: any) => {
          this.notifier.notify('success', 'User created successfully!');
          this.userForm.reset();
          this.getUser();
        }, (error) => {
          this.notifier.notify('error', 'User already exist!');
        })
      }

    }
  }

  getRole() {
    this.authService.getRole().subscribe((resp: any) => {
      this.roles = resp;
    })
  }
  getUser() {

    this.authService.getUser().subscribe((resp: any) => {
      this.rowData = resp;
    })
  }
}
