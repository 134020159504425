import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {
  transform(items: any[], searchText: string, searchColumns: string[]): any[] {
    if (!items || !searchText || !searchColumns.length) {
      return items;
    }

    searchText = searchText.toLowerCase();

    return items.filter(item =>
      searchColumns.some(column => 
        item[column] && item[column].toString().toLowerCase().includes(searchText)
      )
    );
  }
}
