import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-view-button-renderer-component',
  standalone: true,
  imports: [],
  templateUrl: './view-button-renderer-component.component.html',
  styleUrl: './view-button-renderer-component.component.css'
})
export class ViewButtonRendererComponentComponent implements ICellRendererAngularComp {
  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }
  public params: any;
  hide:boolean=false;

  agInit(params: any): void {
    this.params = params;
    this.hide = this.params.hide;
  }
  onView() {
    this.params.onView(this.params.data);
  }
  onActivate(){
    this.params.onActivate(this.params.data);
  }

}
