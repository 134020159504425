import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-loader',
  templateUrl: './progress-loader.component.html',
  styleUrls: ['./progress-loader.component.css'],
  standalone :true,
  imports:[CommonModule]
})
export class ProgressLoaderComponent  {

  @Input() loading: boolean = false;
  
}
