import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { AuthenticationService } from '../service/authentication.service';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [CommonModule, RouterOutlet, RouterLink, RouterLinkActive],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.css'
})
export class LayoutComponent {
  userProfile:any;
  constructor(private authService: AuthenticationService,private router: Router) {
    this.loadUserProfile();
   }

  logout(){

    this.authService.logout();
    this.router.navigate(['/login']);
  }

  loadUserProfile(){
    this.authService.getUserProfile().subscribe((resp)=>{
      this.userProfile = resp;
    })
  }

  isShowTab(name:string)
  {
    var permission = localStorage.getItem('permission') ?? '[]';
    var jsonPermission = JSON.parse(permission);
   return jsonPermission.find((x:any)=> `${x.permission.name}-${x.permission.module.name}` == `View-${name}`) != null;
  }

}
