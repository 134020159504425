import { Component, HostListener } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { StockService } from '../service/stock.service';
import { NotifierModule, NotifierService } from 'angular-notifier';
import { FormsModule } from '@angular/forms';
import { SearchPipe } from '../pipe/SearchPipe';
import { ProgressLoaderComponent } from '../progress-loader/progress-loader.component';

@Component({
  selector: 'app-stock-out',
  standalone: true,
  imports: [AgGridAngular,FormsModule,NotifierModule,ProgressLoaderComponent],
  templateUrl: './stock-out.component.html',
  styleUrl: './stock-out.component.css'
})
export class StockOutComponent {

  constructor(private stockService: StockService,
    private notifier: NotifierService) { 

    }
  rowData:any[] = [];
  rowfilteredData:any[]=[];
  searchText:string="";
  isLoading:boolean=false;
  colDefs: any[] =
    [
     
      { field: "Date" },
      { field: "SoNumber" },
      { field: "Rack" },
      { field: "Level" },
      { field: "Grade" },
      { field: "Type" },
      { field: "Size" },
      { field: "Finish" },
      { field: "Thread" },
      { field: "SoldQuantity", headerName : "Sold Quantity" },
    ]
    @HostListener('document:paste', ['$event'])
  async onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    if (clipboardData) {
      const pastedData = clipboardData.getData('text/plain');
      this.parseData(pastedData);
    }
  }

  parseData(pastedData: string) {
    var headers = this.colDefs.map((col) => col.field);
    const rows = pastedData.split('\n').map(row => row.trim()).filter(row => row);
    var pastedJson = rows.map(row => {
      const columns = row.split('\t').map(column => column.trim());
      return headers.reduce((acc, header, index) => {
        acc[header] = columns[index] || '';
        return acc;
      }, {});
    });
    if(pastedJson?.length > 100)
      {
        this.notifier.notify('warning', `There are ${pastedJson?.length} records available. We have selected the top 100 records.`);
      }
      var itemResponse = this.addNamesToInventory(this.getTop100Records(pastedJson));
  
    this.rowData = itemResponse;
    this.rowfilteredData = itemResponse;
  }

 getTop100Records(data:any) {
    return data
      .slice(0, 100);
  }

  addNamesToInventory(inventory:any) {
    return inventory.map((item:any) => {
        let name = '';

        if (item.Thread?.toLowerCase() === 'ft' || item.Thread?.toLowerCase() === 'ht') {
            name = `${item.Size}-${item.Grade}-${item.Finish}-${item.Type}-${item.Thread}`;
        } else {
            name = `${item.Size}-${item.Grade}-${item.Finish}-${item.Type}`;
        }

        return {
            ...item,
            name: this.modifyString(name)
        };
    });
}

modifyString(input:string) {
  if (input.startsWith('-')) {
      input = input.slice(1);
  }
  if (input.endsWith('-')) {
      input = input.slice(0, -1);
  }
  input = input.replace(/--/g, '-');

  return input.trim();
}

  onCellValueChanged(params:any)
  {
  }
  defaultColDef = {
    editable: true // Make all columns editable by default
  };
  SaveStock=()=>{
    this.isLoading = true;
    this.stockService.saveStock(this.rowData,"Sold","-").subscribe((resp)=>{
      this.rowData= [];
      this.rowfilteredData = [];
      this.isLoading = false;
      this.notifier.notify('success', 'Stock Checkout Successfully!');

    },(error)=>{
      this.isLoading = false;
      this.notifier.notify('error', 'Stock Checkout fail!');
    })
  }
  handleDataChange(){
    const searchPipe = new SearchPipe();
    this.rowfilteredData =  searchPipe.transform(this.rowData, this.searchText, ['SoNumber','Date','Rack','Level','Grade',
    'Type','Size','Finish','Thread']);
  }
}
