import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService } from './service/authentication.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthenticationService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    const isAuthenticated = !!this.authService.getToken();
    if (state.url != "/") {
      var pageName = state.url.substring(1)
      var isallow = this.isShowTab(pageName);
      
      if (!isallow) {
        this.router.navigate(['/']);
      }
    }

    if (!isAuthenticated) {
      this.router.navigate(['/login']); // Redirect to login if not authenticated
    }

    return isAuthenticated; // Allow or deny access
  }
  isShowTab(name: string) {
      var permission = localStorage.getItem('permission') ?? '[]';
    var jsonPermission = JSON.parse(permission);
    return jsonPermission.find((x: any) => `${x.permission.name}-${x.permission.module.name?.toLowerCase()}` == `View-${name?.toLowerCase()}`) != null;
  }
}
