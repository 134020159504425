import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthenticationService } from '../service/authentication.service'
import { Router } from '@angular/router';
import { error } from 'console';
import { NotifierModule, NotifierService } from 'angular-notifier';
import { publicIpv4 } from 'public-ip';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, NotifierModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css',
  providers: []
})
export class LoginComponent {
  myForm: FormGroup;
  constructor(private fb: FormBuilder, private authenticationService: AuthenticationService,
    private router: Router, private notifier: NotifierService
  ) {
  }

  ngOnInit() {
    this.myForm = this.fb.group({
      UserName: ['', [Validators.required, Validators.minLength(3)]],
      Password: ['', [Validators.required]],
    });
  }


  get userName() {
    return this.myForm.get('UserName');
  }

  get password() {
    return this.myForm.get('Password');
  }
  async onSubmit() {
    if (this.myForm.valid) {
      var ip = await publicIpv4()
      this.userLogin(this.myForm.value.UserName, this.myForm.value.Password, ip)

    }
  }

  userLogin(userName: string, password: string, ip: string) {
    console.log("User-IP", ip);

    this.authenticationService.userLogin(userName, password, ip)
      .subscribe((response) => {
        this.setToken(response.token);
        this.setPermissions(response.permission);
        this.redirect("/");
      }, (error: any) => {
        this.notifier.notify('error', 'Invalid Credentials! please try again');
      })
  }

  setToken = (token: string) => {
    localStorage.setItem("token", token)
  }
  setPermissions = (permission: string) => {
    localStorage.setItem("permission", JSON.stringify(permission))
  }

  redirect = (url: string) => {
    this.router.navigate([url]);
  }
}
