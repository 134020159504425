<div class="card">
    <div class="card-header">
        <h1 class="h3 mb-2 text-gray-800">Stock Audit</h1>
    </div>
    <div class="card-body">
        <div class="input-group mb-3">
            <input type="text" (keyup)="handleDataChange()" [(ngModel)]="searchText" placeholder="Search..."
                class="form-control col-4">
            &nbsp;
            <button class="btn btn-danger" type="button" data-toggle="modal" data-target="#deleteModal">Delete
                Records</button>
            &nbsp;
            <button class="btn btn-primary" (click)="exportToExcel()" type="button">Export to Excel</button>
            &nbsp;
            <div>

                <label>Show Future Stock</label>
                &nbsp;
                <input type="checkbox" (change)="showFutureStock()" [(ngModel)]="isFutureStock" />
            </div>
            <button class="btn btn-success ml-auto" type="button" (click)="selectAll()">Select All</button>

        </div>
      
        <ag-grid-angular #agGrid class="ag-theme-quartz" style="height: 500px;" [rowData]="rowFilteredData"
            [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [gridOptions]="gridOptions">
        </ag-grid-angular>

    </div>
</div>
<div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" role="dialog" aria-labelledby="myModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-sm" role="document">

        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Confirmation</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Are you sure you want to delete?</p>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" type="button" data-dismiss="modal">Close</button>
                <button class="btn btn-primary" type="button" data-dismiss="modal"
                    (click)="DeleteRecords()">Delete</button>
            </div>
        </div>
    </div>
</div>
<notifier-container></notifier-container>