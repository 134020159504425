import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router'; // Import Router
import { AuthenticationService } from './service/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private authService: AuthenticationService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get the JWT token from the AuthService
    const token = this.authService.getToken();

    // Clone the request to add the new header
    if (token) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    // Handle the request
    return next.handle(req).pipe(
      catchError(err => {
        
        // Handle unauthorized error (e.g., redirect to login)
        if (err.status === 401) {
          // Optionally clear token from storage
          this.authService.logout(); // Implement this method in AuthService to clear token
          this.router.navigate(['/login']);
        }
        return throwError(err);
      })
    );
  }
}
