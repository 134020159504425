import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-approve-button-renderer-component',
  standalone: true,
  imports: [],
  templateUrl: './approve-button-renderer-component.component.html',
  styleUrl: './approve-button-renderer-component.component.css'
})
export class ApproveButtonRendererComponentComponent implements ICellRendererAngularComp {
  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }
  public params: any;

  agInit(params: any): void {
    this.params = params;
  }
  onApprove() {
    this.params.onApprove(this.params.data);
  }
}
