    <!-- Outer Row -->
    <div class="row justify-content-center">

        <div class="col-xl-10 col-lg-12 col-md-9">

            <div class="card o-hidden border-0 shadow-lg my-5">
                <div class="card-body p-0">
                    <!-- Nested Row within Card Body -->
                    <div class="row">
                        <div class="col-lg-6 d-none d-lg-block bg-login-image"></div>
                        <div class="col-lg-6">
                            <div class="p-5">
                                <div class="text-center">
                                    <h1 class="h4 text-gray-900 mb-4">Welcome</h1>
                                </div>
                                <form class="user" [formGroup]="myForm" (ngSubmit)="onSubmit()">
                                    <div class="form-group">
                                        <input placeholder="User Name" type="text" class="form-control form-control-user" formControlName="UserName">
                                        <div *ngIf="userName.invalid && (userName.dirty || userName.touched)">
                                            <div *ngIf="userName.errors?.required">User Name is required.</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <input placeholder="Password" type="password" class="form-control form-control-user" formControlName="Password"
                                        >
                                        <div *ngIf="password.invalid && (password.dirty || password.touched)">
                                            <div *ngIf="password.errors?.required">Password is required.</div>
                                          </div>
                                    </div>

                                    <button type="submit" [disabled]="myForm.invalid" class="btn btn-primary btn-user btn-block">
                                        Login
                                    </button>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
    <notifier-container></notifier-container>