<div class="card">
    <div class="card-header">
        <h1 class="h3 mb-2 text-gray-800">Stock In</h1>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="input-group mb-3">
                <input type="text" (keyup)="handleDataChange()" [(ngModel)]="searchText" placeholder="Search..."
                    class="form-control col-4">
                <select class="form-control col-4 mx-2" [(ngModel)]="stockType">
                    <option>Import</option>
                    <option>Local</option>
                    <option>Future Stock</option>
                </select>
            </div>
            <div class="input-group mb-3">
                <textarea [(ngModel)]="message" placeholder="Message"
                    class="form-control col-10"></textarea>
               
            </div>
        </div>
        <br />
        <ag-grid-angular (paste)="onPaste($event)" [defaultColDef]="defaultColDef"
            (cellValueChanged)="onCellValueChanged($event)" [rowData]="rowfilteredData" [columnDefs]="colDefs"
            class="ag-theme-quartz" style="height: 500px;" />
        <div class="float-right">
            <button [disabled]="isLoading"  type="button" class="btn btn-primary btn-user btn-block m-1" (click)="SaveStock()">
                {{isLoading?'Loading...':'Submit'}}
            </button>
        </div>
    </div>
</div>

<notifier-container></notifier-container>
<app-progress-loader [loading]="isLoading" ></app-progress-loader>
