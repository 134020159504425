import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-action-button-renderer-component',
  standalone: true,
  imports: [],
  templateUrl: './action-button-renderer-component.component.html',
  styleUrl: './action-button-renderer-component.component.css'
})
export class ActionButtonRendererComponentComponent implements ICellRendererAngularComp {
  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }
  private params: any;

  agInit(params: any): void {
    this.params = params;
  }
  onDelete()
{
  this.params.onDelete(this.params.data); 
}
onEdit()
{
  this.params.onEdit(this.params.data); 
}
}
